






















































import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class CoachIndex extends Vue {

  get realName() {
    return localStorage.getItem('Keep_RealName')
  }

  get faceUrl() {
    return localStorage.getItem('Keep_FaceUrl')
  }

}

